// import React, { useState } from 'react';
// import millify from 'millify';
// import { Link } from 'react-router-dom';
// import { Card, Row, Col, Input } from 'antd';
// import { useGetCryptosQuery } from '../services/cryptoApi';

// const Cryptocurrencies = ({ simplified }) => {
//   const count = simplified ? 10 : 100;
//   const { data: cryptosList, isFetching } = useGetCryptosQuery(count);
//   // the fetching of the coins
//   const [cryptos, setCryptos] = useState(cryptosList?.data?.coins);

//   //console.log(cryptos);
//   if (isFetching) return 'Loading...';

//   //to fetch the top 10 cryptos when i click the home page coming through props also pass count inside the usegetcryptoquery add to home page cryptoapi also

//   return (
//     <>
//       <Row gutter={[25, 25]} className='crypto-card-container'>
//         {cryptos?.map((currency) => (
//           <Col xs={24} sm={12} lg={6} className='crypto-card' key={currency.id}>
//             <Link to={`/crypto/${currency.id}`}>
//               <Card
//                 title={`${currency.rank}. ${currency.name}`}
//                 extra={<img className='crypto-image' src={currency.iconUrl} />}
//                 hoverable
//               >
//                 <p>Price:{millify(currency.price)}</p>
//                 <p>Market Cap:{millify(currency.marketCap)}</p>
//                 <p>Daily Change:{millify(currency.change)}</p>
//               </Card>
//             </Link>
//           </Col>
//         ))}
//       </Row>
//     </>
//   );
// };

// export default Cryptocurrencies;

import React, { useEffect, useState } from 'react';
import millify from 'millify';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Input } from 'antd';
import { useGetCryptosQuery } from '../services/cryptoApi';
import Loader from './Loader';

const Cryptocurrencies = ({ simplified }) => {
  const count = simplified ? 10 : 100;
  const { data: cryptosList, isFetching } = useGetCryptosQuery(count);
  // the fetching of the coins
  // before about filter
  // const [cryptos, setCryptos] = useState(cryptosList?.data?.coins);
  const [cryptos, setCryptos] = useState([]);
  {
    /* to filter back the 10 cryptocurrencies */
  }
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // setCryptos(cryptosList?.data?.coins);

    const filterData = cryptosList?.data?.coins.filter((coin) =>
      coin.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setCryptos(filterData);
  }, [cryptosList, searchTerm]);

  //console.log(cryptos);
  if (isFetching) return <Loader />;

  //to fetch the top 10 cryptos when i click the home page coming through props also pass count inside the usegetcryptoquery add to home page cryptoapi also

  return (
    <>
      {/* to filter back the 10 cryptocurrencies */}
      {/* <div className='search-crypto'>
        <Input
          placeholder='Search Cryptocurrency'
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
      {/* to remove the search button from home page */}

      {!simplified && (
        <div className='search-crypto'>
          <Input
            placeholder='Search Cryptocurrency'
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}
      <Row gutter={[25, 25]} className='crypto-card-container'>
        {cryptos?.map((currency) => (
          <Col
            xs={24}
            sm={12}
            lg={6}
            className='crypto-card'
            key={currency.uuid}
          >
            <Link to={`/crypto/${currency.uuid}`}>
              <Card
                title={`${currency.rank}. ${currency.name}`}
                extra={<img className='crypto-image' src={currency.iconUrl} />}
                hoverable
              >
                <p>Price:{millify(currency.price)}</p>
                <p>Market Cap:{millify(currency.marketCap)}</p>
                <p>Daily Change:{millify(currency.change)}</p>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Cryptocurrencies;
