import React from 'react';
import millify from 'millify';
import { Collapse, Row, Col, Typography, Avatar } from 'antd';
import HTMLReactParser from 'html-react-parser';
import Loader from './Loader';

//import Loader from './Loader';

const { Text } = Typography;
const { Panel } = Collapse;

const Exchanges = () => {
  const [exchangeData, setExchangeData] = React.useState([]);

  React.useEffect(() => {
    fetch(`https://coinranking1.p.rapidapi.com/coin/Qwsogvtv82FCd/exchanges`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': 'dbd58df296mshcd7936a19e9d5d0p1edd37jsnd47d61b05d30',
        'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com',
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        setExchangeData(data.data.exchanges);
      });
  }, [{}]);
  // const exchangesList = data?.data?.exchanges;

  if (!exchangeData) return <Loader />;
  return (
    <>
      <Row className='ex'>
        <Col span={6}>Exchanges</Col>
        <Col span={6}>24h Trade Volume</Col>
        <Col span={6}>Markets</Col>
        <Col span={6}>Price</Col>
      </Row>
      <Row>
        {exchangeData.map((exchange) => (
          <Col span={24}>
            <Collapse>
              <Panel
                key={exchange.uuid}
                showArrow={false}
                header={
                  <Row key={exchange.uuid}>
                    <Col span={6}>
                      <Text>
                        <strong>{exchange.rank}.</strong>
                      </Text>
                      <Avatar
                        className='exchange-image'
                        src={exchange.iconUrl}
                      />
                      <Text>
                        <strong>{exchange.name}</strong>
                      </Text>
                    </Col>
                    <Col span={6}>${millify(exchange['24hVolume'])}</Col>
                    <Col span={6}>{millify(exchange.numberOfMarkets)}</Col>
                    <Col span={6}>{millify(exchange.price)}</Col>
                  </Row>
                }
              >
                {/* {HTMLReactParser(exchange.description || '')} */}
                {/* {HTMLReactParser(exchange.iconUrl)} */}
                <a href={exchange.coinrankingUrl} target='_blank'>
                  {exchange.name}{' '}
                </a>
              </Panel>
            </Collapse>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Exchanges;
