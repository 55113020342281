import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const cryptoApiHeaders = {
//   'X-RapidAPI-Key': 'dbd58df296mshcd7936a19e9d5d0p1edd37jsnd47d61b05d30',
//   'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com',
// };

// const baseUrl = 'https://coinranking1.p.rapidapi.com';

// const createRequest = (url) => ({ url, headers: cryptoApiHeaders });

// export const cryptoApi = createApi({
//   reducerPath: 'cryptoApi',
//   baseQuery: fetchBaseQuery({ baseUrl }),
//   endpoints: (builder) => ({
//     getCryptos: builder.query({
//       // not their at first count props
//       query: (count) => createRequest(`/coins?limit=${count}`),
//     }),
//   }),
// });

// For CryptoDetails

const cryptoApiHeaders = {
  'X-RapidAPI-Key': 'dbd58df296mshcd7936a19e9d5d0p1edd37jsnd47d61b05d30',
  'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com',
};

const baseUrl = 'https://coinranking1.p.rapidapi.com';

const createRequest = (url) => ({ url, headers: cryptoApiHeaders });

export const cryptoApi = createApi({
  reducerPath: 'cryptoApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getCryptos: builder.query({
      query: (count) => createRequest(`/coins?limit=${count}`),
    }),
    getCryptoDetails: builder.query({
      query: (coinId) => createRequest(`/coin/${coinId}`),
    }),
    getCryptoHistory: builder.query({
      query: ({ coinId, timePeriod }) =>
        createRequest(`coin/${coinId}/history/${timePeriod}`),
    }),
  }),
});

export const {
  useGetCryptosQuery,
  useGetCryptoDetailsQuery,
  useGetCryptoHistoryQuery,
} = cryptoApi;
// before CryptoDetails

// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const cryptoApiHeaders = {
//   'X-RapidAPI-Key': 'dbd58df296mshcd7936a19e9d5d0p1edd37jsnd47d61b05d30',
//   'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com',
// };

// const baseUrl = 'https://coinranking1.p.rapidapi.com';

// const createRequest = (url) => ({ url, headers: cryptoApiHeaders });

// export const cryptoApi = createApi({
//   reducerPath: 'cryptoApi',
//   baseQuery: fetchBaseQuery({ baseUrl }),
//   endpoints: (builder) => ({
//     getCryptos: builder.query({
//       // not their at first count props
//       query: () => createRequest(`/coins`),
//     }),
//   }),
// });

// export const { useGetCryptosQuery } = cryptoApi;

// const options = {
//   method: 'GET',
//   url: 'https://coinranking1.p.rapidapi.com/coins',
//   params: {
//     referenceCurrencyUuid: 'yhjMzLPhuIDl',
//     timePeriod: '24h',
//     'tiers[0]': '1',
//     orderBy: 'marketCap',
//     orderDirection: 'desc',
//     limit: '50',
//     offset: '0',
//   },
//   headers: {
//     'X-RapidAPI-Key': 'dbd58df296mshcd7936a19e9d5d0p1edd37jsnd47d61b05d30',
//     'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com',
//   },
// };

// const option = {
//   method: 'GET',
//   url: 'https://coinranking1.p.rapidapi.com/exchange/-zdvbieRdZ/coins',
//   params: {
//     referenceCurrencyUuid: 'yhjMzLPhuIDl',
//     limit: '50',
//     offset: '0',
//     orderBy: '24hVolume',
//     orderDirection: 'desc',
//   },
//   headers: {
//     'X-RapidAPI-Key': 'dbd58df296mshcd7936a19e9d5d0p1edd37jsnd47d61b05d30',
//     'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com',
//   },
// };
